@use '@/styles/utils/mixins.scss' as *;

.personalizedOfferingWrapper {
  background-color: $primary;
  border-radius: 1.25rem;
  padding: 3.75rem;
  color: $white;
  text-align: center;

  @include mob() {
    padding: 6.25rem 0;
  }

  .personalizedOffering {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 6.25rem;

    .countWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
      justify-content: center;
      gap: 3.75rem;

      @include tab() {
        grid-template-columns: repeat(1, 1fr);
        gap: 6.25rem;
      }

      .countItem {
        h2 {
          font-size: 6rem;
          margin-bottom: 1rem;
        }
        p {
          font-size: 1.5rem;
        }
      }
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3.75rem;

      .heading {
        p {
          margin-bottom: 0.5rem;
        }
      }

      .paragraph {
        text-align: center;
        max-width: 60rem;
      }
    }

    .quickLinkDivider {
      border-color: $primaryAlt !important;
      width: 100%;
      margin: 0;
    }
  }
}
